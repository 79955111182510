import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, CircularProgress, Paper, Stack, styled, TextField } from '@mui/material'
import _ from 'lodash'
import Validator from 'validator'
import { API } from '../../api/api'
import { MenuItemType } from '../../model/MenuItemType'
import { changeMenuItem } from '../../state/menuItemActions'
import { setTitle } from '../../state/titleActions'
import { StatusRequest } from '../../api/request'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { routesDetails } from '../../routes/routesDetails'
import { showSnackbarMessage } from '../../state/messageActions'
import { Status } from '../../api/response'
import { useStateDispatchContext } from '../../state/stateContext'
import { useStableNavigate } from '../../routes/StableNavigateContext'
import { useParams } from 'react-router'
import { handleApiError } from '../../state/apiErrorActions'

const strings = {
    button: {
        refresh: 'Odśwież',
        save: 'Zapisz',
    },
    error: {
        nameLength: 'Wymagana wartość w przedziale <1, 64> znaki',
        nameRequired: 'Nazwa jest wymagana',
        requiredPhotosInvalid: 'Wymagana jest liczba większa lub równa 0',
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
    label: {
        name: 'Nazwa',
        requiredPhotos: 'Wymagane zdjęcia',
    },
    message: {
        added: 'Nowy status została dodany',
        edited: 'Status został zaktualizowany',
    },
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

enum FormFieldNames {
    Name = 'name',
    RequiredPhotos = 'requiredPhotos',
}

interface FormErrors {
    name?: string
    requiredPhotos?: string
}

const StatusDetailsPage: React.FunctionComponent = () => {
    const { appDispatch } = useStateDispatchContext()
    const navigate = useStableNavigate()
    const { id } = useParams()

    const [loading, setLoading] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const [errors, setErrors] = useState<FormErrors>({})
    const [name, setName] = useState<string>('')
    const [requiredPhotos, setRequiredPhotos] = useState<string>('')

    const statusId = useMemo(() => {
        return parseInt(id ?? '0', 10)
    }, [id])

    const loadStatus = useCallback(() => {
        if (statusId === 0) {
            setLoaded(true)

            return
        }

        if (loading) {
            return
        }

        setLoading(true)
        setRefresh(false)
        API.statuses
            .details(statusId)
            .then((status) => {
                setName(status.name)
                setRequiredPhotos(status.requiredPhotos.toString())
                setLoaded(true)
            })
            .catch((error) => {
                setRefresh(true)
                appDispatch(handleApiError(error))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [statusId, loading])

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        switch (event.currentTarget.name) {
            case FormFieldNames.Name: {
                setName(event.target.value)
                break
            }
            case FormFieldNames.RequiredPhotos: {
                setRequiredPhotos(event.target.value)
                break
            }
        }
    }, [])

    function handleSaveClick(): void {
        const errors: FormErrors = {}

        if (Validator.isEmpty(name)) {
            errors.name = strings.error.nameRequired
        } else if (!Validator.isLength(name, { min: 1, max: 64 })) {
            errors.name = strings.error.nameLength
        }

        // eslint-disable-next-line camelcase
        if (!Validator.isNumeric(requiredPhotos, { no_symbols: true })) {
            errors.requiredPhotos = strings.error.requiredPhotosInvalid
        }

        setErrors(errors)

        if (!_.isEmpty(errors)) {
            return
        }

        const request = new StatusRequest(name, parseInt(requiredPhotos, 10))

        appDispatch(setInProgress())

        const promise: Promise<Status> = statusId !== 0 ? API.statuses.edit(statusId, request) : API.statuses.add(request)

        promise
            .then((response) => {
                appDispatch(setSuccess())

                if (statusId === 0) {
                    appDispatch(showSnackbarMessage(strings.message.added))
                    navigate(routesDetails.authenticated.status.to(response.id), { replace: true })
                } else {
                    appDispatch(showSnackbarMessage(strings.message.edited))
                }
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))

                if (error === null) {
                    appDispatch(showSnackbarMessage(strings.error.unknown))
                }
            })
    }

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.Statuses))
        appDispatch(setTitle(MenuItemType.Statuses))
    }, [])

    useEffect(() => {
        const number = parseInt(id ?? '0', 10)
        if (isNaN(number)) {
            navigate(routesDetails.authenticated.status.to(0), { replace: true })
        } else {
            loadStatus()
        }
    }, [])

    return (
        <StyledPaper elevation={2}>
            {loading && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Stack>
            )}

            {refresh && !loading && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <Button
                        onClick={loadStatus}
                        variant="contained"
                    >
                        {strings.button.refresh}
                    </Button>
                </Stack>
            )}

            {loaded && (
                <Stack
                    direction="column"
                    spacing={2}
                    ml={3}
                    mr={3}
                >
                    <TextField
                        id={FormFieldNames.Name}
                        name={FormFieldNames.Name}
                        value={name}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.name}
                        margin="none"
                        onChange={onTextFieldChange}
                        helperText={errors.name}
                        error={errors.name !== undefined}
                        inputProps={{ maxLength: 64 }}
                    />

                    <TextField
                        id={FormFieldNames.RequiredPhotos}
                        name={FormFieldNames.RequiredPhotos}
                        value={requiredPhotos}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.requiredPhotos}
                        margin="none"
                        type="number"
                        onChange={onTextFieldChange}
                        helperText={errors.requiredPhotos}
                        error={errors.requiredPhotos !== undefined}
                        inputProps={{ maxLength: 64 }}
                    />

                    <Stack
                        direction="row"
                        justifyContent="left"
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSaveClick}
                        >
                            {strings.button.save}
                        </Button>
                    </Stack>
                </Stack>
            )}
        </StyledPaper>
    )
}

export default React.memo(StatusDetailsPage)
