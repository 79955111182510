import { UserRole } from '../response'

export class UserCreateRequest {
    constructor(
        readonly branchId: number,
        readonly jobPositionId: number,
        readonly email: string,
        readonly password: string,
        readonly firstName: string,
        readonly lastName: string,
        readonly phoneNumber: string,
        readonly rate: string,
        readonly branchRange: number | null,
        readonly useSmsNotifications: boolean,
        readonly roles: UserRole[],
        readonly hiredAt: Date
    ) {}
}
