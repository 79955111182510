import { Branch } from './Branch'
import { JobPosition } from './JobPosition'

export interface User {
    readonly id: number
    readonly branch: Branch
    readonly jobPosition: JobPosition
    readonly email: string
    readonly roles: UserRole[]
    readonly firstName: string
    readonly lastName: string
    readonly phoneNumber: string | null
    readonly rate: string
    readonly branchRange: number | null
    readonly useSmsNotifications: boolean
    readonly status: UserStatus
    readonly hiredAt: Date
    readonly firedAt: Date | null
    readonly forbiddenPlaces: number
}

export enum UserStatus {
    Active = 'active',
    Locked = 'locked',
    Removed = 'removed',
}

export enum UserRole {
    User = 'ROLE_USER',
    Panel = 'ROLE_PANEL',
    Mobile = 'ROLE_MOBILE',
    ManageUsers = 'ROLE_MANAGE_USERS',
    ShowUserMap = 'ROLE_SHOW_USER_MAP',
    ResetUserPhone = 'ROLE_RESET_USER_PHONE',
    ManageAdditionalBranches = 'ROLE_MANAGE_ADDITIONAL_BRANCHES',
    ManageCars = 'ROLE_MANAGE_CARS',
    ManageBranches = 'ROLE_MANAGE_BRANCHES',
    ManageStatuses = 'ROLE_MANAGE_STATUSES',
    ManageJobPositions = 'ROLE_MANAGE_JOB_POSITIONS',
    ManageHolidays = 'ROLE_MANAGE_HOLIDAYS',
    ManageForbiddenPlaces = 'ROLE_MANAGE_FORBIDDEN_PLACES',
    ManageBranchSchedules = 'ROLE_MANAGE_BRANCH_SCHEDULES',
    ManageAllSchedules = 'ROLE_MANAGE_ALL_SCHEDULES',
    ViewBranchSchedules = 'ROLE_VIEW_BRANCH_SCHEDULES',
    ViewAllSchedules = 'ROLE_VIEW_ALL_SCHEDULES',
    ManagePastSchedules = 'ROLE_MANAGE_PAST_SCHEDULES',
    CloseSchedules = 'ROLE_CLOSE_SCHEDULES',
    ManageBranchUserShifts = 'ROLE_MANAGE_BRANCH_USER_SHIFTS',
    ManageAllUserShifts = 'ROLE_MANAGE_ALL_USER_SHIFTS',
    ManualAddUserShifts = 'ROLE_MANUAL_ADD_USER_SHIFTS',
    HideUserShifts = 'ROLE_HIDE_USER_SHIFTS',
    EndUserShifts = 'ROLE_END_USER_SHIFTS',
    ExportUserSchedule = 'ROLE_EXPORT_USER_SCHEDULE',
    ShowScheduleHistory = 'ROLE_SHOW_SCHEDULE_HISTORY',
    ManageBranchTasks = 'ROLE_MANAGE_BRANCH_TASKS',
    ManageAllTasks = 'ROLE_MANAGE_ALL_TASKS',
    AllowShiftsOutsideBranch = 'ROLE_ALLOW_SHIFTS_OUTSIDE_BRANCH',
}
