import { DateTime } from '../DateTime'
import { UserInfo } from './UserInfo'

export interface ShiftHistory {
    readonly user: UserInfo
    readonly start: DateTime
    readonly end: DateTime
    readonly entryType: HistoryEntryType
    readonly createdAt: DateTime
}

export enum HistoryEntryType {
    Added = 'added',
    Edited = 'edited',
    Removed = 'removed',
    Restored = 'restored',
}
