import React, { useCallback, useMemo } from 'react'
import { Schedule, ScheduleStatus, ScheduleUser, UserRole, hasOneOfRoles, hasRole } from '../../api/response'
import dayjs from 'dayjs'
import { styled } from '@mui/material'
import { ScheduleShiftDialogData } from './ScheduleShiftDialog'
import ScheduleTableBodyRow from './ScheduleTableBodyRow'
import _ from 'lodash'
import { useStateContext, useStateDispatchContext } from '../../state/stateContext'
import { ScheduleDayDialogData } from './ScheduleDayDialog'
import { UserShiftDialogData } from './UserShiftDialog'
import { SplitUserShiftDialogData } from './SplitUserShiftDialog'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { API } from '../../api/api'
import { handleApiError } from '../../state/apiErrorActions'
import FileSaver from 'file-saver'
import { ScheduleHistoryDialogData } from './ScheduleHistoryDialog'

const colors = {
    beeswax: '#fff9c4',
    blue: '#bbdefb',
    red: '#ffcdd2',
    pink: '#f8bbd0',
    brown: '#d7ccc8',
}

const StyledTableBody = styled('tbody')({
    '& th': {
        left: 0,
        zIndex: 1,
        minWidth: 300,
    },
})

const StyledGroupRow = styled('tr')(({ theme }) => ({
    '& th': {
        ...theme.typography.h6,
        textAlign: 'center',
        backgroundColor: colors.beeswax,
    },
    '& td': {
        backgroundColor: colors.beeswax,
    },
}))

interface OwnProps {
    readonly schedule: Schedule
    readonly status: ScheduleStatus | null
    readonly date: dayjs.Dayjs
    readonly showAll: boolean
    readonly canShowHistory: boolean
    readonly canExportSchedule: boolean
    readonly openHistoryDialog: (data: ScheduleHistoryDialogData) => void
    readonly openDayDialog: (data: ScheduleDayDialogData) => void
    readonly openScheduleShiftDialog: (data: ScheduleShiftDialogData) => void
    readonly openUserShiftDialog: (data: UserShiftDialogData) => void
    readonly openSplitUserShiftDialog: (data: SplitUserShiftDialogData) => void
}

const ScheduleTableBody: React.FunctionComponent<OwnProps> = ({
    schedule,
    status,
    date,
    showAll,
    canShowHistory,
    canExportSchedule,
    openHistoryDialog,
    openDayDialog,
    openScheduleShiftDialog,
    openUserShiftDialog,
    openSplitUserShiftDialog,
}) => {
    const { sessionState } = useStateContext()
    const { appDispatch } = useStateDispatchContext()

    const dayColors = useMemo(() => {
        return _.range(1, date.daysInMonth() + 1, 1)
            .map((day) => dayjs().year(date.year()).month(date.month()).date(day))
            .map((d) => {
                const holiday = schedule.holidays.find((item) => {
                    if (d.isSame(item.date, 'day')) {
                        return true
                    }

                    return false
                })

                if (holiday !== undefined) {
                    return colors.pink
                } else if (d.day() === 0) {
                    return colors.red
                } else if (d.day() === 6) {
                    return colors.brown
                }

                return colors.blue
            })
    }, [schedule, date])

    const editableDays = useMemo(() => {
        const today = dayjs().startOf('day')
        const pastMonth = (date.year() === today.year() && date.month() < today.month()) || date.year() < today.year()
        const hasManagePastRole = hasRole(sessionState.account!, UserRole.ManagePastSchedules)
        const hasManageRole =
            schedule !== null &&
            ((schedule.branch.id !== sessionState.account!.branch.id && hasRole(sessionState.account!, UserRole.ManageAllSchedules)) ||
                (schedule.branch.id === sessionState.account!.branch.id &&
                    hasOneOfRoles(sessionState.account!, [UserRole.ManageBranchSchedules, UserRole.ManageAllSchedules])))

        if (schedule === null || status === null || status === ScheduleStatus.Closed || !hasManageRole || (pastMonth && !hasManagePastRole)) {
            return _.range(1, date.daysInMonth() + 1, 1).map(() => false)
        }

        const futureMonth =
            (date.year() === today.year() && date.month() > today.month()) ||
            (date.year() === today.year() && date.month() === today.month() && today.date() === 1) ||
            date.year() > today.year()
        if (futureMonth || hasManagePastRole) {
            return _.range(1, date.daysInMonth() + 1, 1).map(() => true)
        }

        return _.range(1, date.daysInMonth() + 1, 1)
            .map((day) => dayjs().year(date.year()).month(date.month()).date(day))
            .map((d) => !d.isBefore(today, 'day'))
    }, [schedule, date])

    const canAddUserShifts = useMemo(() => {
        return hasRole(sessionState.account!, UserRole.ManualAddUserShifts)
    }, [schedule])

    const canEditUserShifts = useMemo(() => {
        if (sessionState.account!.branch.id !== schedule.branch.id) {
            return hasRole(sessionState.account!, UserRole.ManageAllUserShifts)
        }

        return hasOneOfRoles(sessionState.account!, [UserRole.ManageBranchUserShifts, UserRole.ManageAllUserShifts])
    }, [schedule])

    const canHideUserShifts = useMemo(() => {
        if (!hasRole(sessionState.account!, UserRole.HideUserShifts)) {
            return false
        }

        return canEditUserShifts
    }, [schedule])

    const canEndUserShifts = useMemo(() => {
        if (!hasRole(sessionState.account!, UserRole.EndUserShifts)) {
            return false
        }

        return canEditUserShifts
    }, [schedule])

    const exportUserSchedule = useCallback(
        (user: ScheduleUser): void => {
            appDispatch(setInProgress())

            API.schedule
                .exportUser(schedule.id, user.id)
                .then((blob: Blob) => {
                    const firstName = user.firstName.toLowerCase()
                    const lastName = user.lastName.toLowerCase()

                    FileSaver.saveAs(blob, `raport-godzin-${firstName}-${lastName}-${schedule.month}-${schedule.year}.xlsx`)

                    appDispatch(setSuccess())
                })
                .catch((error) => {
                    appDispatch(handleApiError(error))
                    appDispatch(setFailure())
                })
        },
        [schedule]
    )

    return (
        <StyledTableBody>
            {schedule.groups.map((group, index) => (
                <React.Fragment key={index}>
                    <StyledGroupRow key={index}>
                        <th>{group.jobPosition.name}</th>
                        <td colSpan={date.daysInMonth()}>&nbsp;</td>
                    </StyledGroupRow>
                    {group.users.map((user, index) => (
                        <ScheduleTableBodyRow
                            key={index}
                            scheduleId={schedule.id}
                            date={date}
                            user={user}
                            showAll={showAll}
                            dayColors={dayColors}
                            editableDays={editableDays}
                            canShowHistory={canShowHistory}
                            canAddUserShifts={canAddUserShifts}
                            canEditUserShifts={canEditUserShifts}
                            canHideUserShifts={canHideUserShifts}
                            canEndUserShifts={canEndUserShifts}
                            canExportUserSchedule={canExportSchedule}
                            openHistoryDialog={openHistoryDialog}
                            openDayDialog={openDayDialog}
                            openScheduleShiftDialog={openScheduleShiftDialog}
                            openUserShiftDialog={openUserShiftDialog}
                            openSplitUserShiftDialog={openSplitUserShiftDialog}
                            exportUserSchedule={exportUserSchedule}
                        />
                    ))}
                </React.Fragment>
            ))}
        </StyledTableBody>
    )
}

export default React.memo(ScheduleTableBody)
